$grid: (
    type: grid,
    default: default,
    generateGridClassesFromBreakpoints: true,
    columns: 12,
    column-gap: 1rem,
    row-gap: 1.5rem
);
$breakpoints: (
    desktop: 64rem,
    dividedteaser: 48em,
    history: 52em,
    history-lg: 74.3em,
    screenSmall: 767px
);
            @import "STYLE_UTILS/_base";.sw-dividedteaser {
  display: grid;
  grid-template-columns: 1fr;
  position: relative;

  @include media('>=dividedteaser') {
    grid-auto-columns: 1fr;
  }

  .cmp-image__image {
    max-height: rem(270);
    object-fit: cover;
    object-position: center;

    @include media('>=dividedteaser') {
      max-height: none;
    }
  }

  .sw-video {
    height: 100%;
  }
}

.sw-dividedteaser__content {
  background-color: var(--sw-dividedteaser-bgc);
  padding: map-get($grid, row-gap) map-get($grid, column-gap) * 3;
  text-align: left;

  @include media('>=dividedteaser') {
    grid-column: 2 / calc(var(--sw-dividedteaser-columns, 2) + 1);
    grid-row: 1;
  }

  > *:not(:first-child) {
    margin-top: map-get($grid, row-gap);
  }
}

.sw-dividedteaser--center {
  align-items: center;
}

.sw-dividedteaser--stretchImage {
  grid-auto-rows: 1fr;

  .media {
    position: relative;
  }

  .cmp-image__image {
    height: 100%;
    max-height: none;
    position: absolute;
    width: 100%;
  }
}

.sw-dividedteaser--orderReverse {
  @include media('>=dividedteaser') {
    .media {
      order: 2;
    }
    
    direction: rtl;

    .sw-dividedteaser__content {
      direction: ltr;
    }
  }
}
